import "./style.css";
import {useEffect, useState} from "react";
import {overlayStyle} from "./MasySearchWindow";
import {masyReplace, scalingX, scalingY} from "../globals";
import MasyCloseButton from "./MasyCloseButton";

export default function MasySearchWindowF2({data, onOK, onESC, onAction, setData, isMobil, setTooltip}){
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState([0, 0]);

    function onKey(e){
        console.log(selected);
        if(e.key === "ArrowRight"){
            if(selected[0] < data.searchF2colHeader.length - 1){
                setSelected(arr => [arr[0] + 1, arr[1]]);
                onAction("action: {||DbSkip(" + selected[1] + ")}");
                setQuery("");
            }
            e.preventDefault();
        }
        if(e.key === "ArrowLeft"){
            if(selected[0] > 0){
                setSelected(arr => [arr[0] - 1, arr[1]]);
                onAction("action: {||DbSkip(" + selected[1] + ")}");
                setQuery("");
            }
            e.preventDefault();
        }
        if(e.key === "ArrowUp"){
            if(selected[1] > 0){
                setSelected(arr => [arr[0], arr[1] - 1]);
            }
            e.preventDefault();
        }
        if(e.key === "ArrowDown"){
            if(selected[1] < data.searchF2data.length - 1){
                setSelected(arr => [arr[0], arr[1] + 1]);
            }
            e.preventDefault();
        }
        if(e.key === "Enter"){
            onOK(selected[1]);
            e.preventDefault();
        }
        if(e.key === "Escape"){
            onOK(selected[1]);
            e.preventDefault();
        }
    }

    useEffect(() => {
        window.addEventListener("keydown", onKey, true);
        document.getElementById("input")?.focus();

        if(data.searchF2buffer === ""){
            setData(data => {
                data.searchF2buffer = null;
                return data;
            });
            setSelected(sel => [sel[0], 0]);
        }
        if(data.searchF2data && data.searchF2data.length <= selected[1] && selected[1] !== 0){
            setSelected(sel => [sel[0], 0]);
        }

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    }, [data]);

    useEffect(() => {
        onAction("action: {||SEK_TABWY(NIL,NIL,NIL,NIL,NIL,\"" + query + "\",\"" + selected[0] + "\")}");
    }, [query, selected]);

    return (
        <div className={"backgroundBlur"}>
            <div style={overlayStyle(isMobil)}>
                <div className={"title"}>{data.searchF2title}</div>
                <div className={"toolbar"}>
                    <input onChange={e => setQuery(e.target.value)} value={query} id={"input"}
                           autoComplete={"off"} placeholder={"Suche nach " + data.searchF2for}
                           className={"searchInput"}/>
                    {data.searchF2buttons?.map((button) =>
                        button.action.includes("DC_ReadGuiEvent(5") && !isMobil ?
                            <MasyCloseButton text={masyReplace(button.caption)} onAction={() => onESC()}/> :
                            <button
                                className={"button"}
                                style={{
                                    width: isMobil ? "auto" : button.sizex * scalingX,
                                    height: button.sizey * scalingY,
                                    margin: 10
                                }}
                                onClick={() => {
                                    if(button.action.includes("DC_ReadGuiEvent(6")){
                                        onOK(selected[1]);
                                    }
                                    else if(button.action.includes("DC_ReadGuiEvent(5")){
                                        onESC();
                                    }
                                }}
                            >
                                {masyReplace(button.caption)}
                            </button>
                    )}
                </div>
                <div style={{
                    gridTemplateColumns: data.searchF2colHeader?.map(() => "1fr").join(" ")
                }} className={"grid"}>
                    {data.searchF2colHeader?.map((col, i) =>
                        <div
                            className={"gridHeaderElement"}
                            style={{
                                borderRightWidth: data.searchF2colHeader.length - 1 === i ? 0 : 1
                            }}
                            onClick={() => setSelected([i, 0])}>{col}</div>
                    )}
                    {data.searchF2data?.map((row, y) =>
                        row.map((col, x) =>
                            <div
                                className={"gridElement"}
                                style={{
                                    borderRightWidth: data.searchF2colHeader.length - 1 == x ? 0 : 1,
                                    background: x === selected[0] && y === selected[1] ? "var(--primary)" : y === selected[1] ? "var(--secondary)" : y % 2 === 0 ? "white" : "var(--secondary30)"
                                }}
                                onMouseEnter={() => setTooltip(col)}
                                onMouseLeave={() => setTooltip(null)}
                                onClick={() => {
                                    if(selected[1] === y){
                                        setSelected([x, y]);
                                        onAction("action: {||DbSkip(" + y + ")}");
                                        setQuery("");
                                    }
                                    else if(selected[0] === x){
                                        setSelected([x, y]);
                                    }
                                    else{
                                        setSelected([selected[0], y]);
                                    }
                                }}>{col}</div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}