import "./style.css";
import first from "../img/first.png";
import up from "../img/up.png";
import down from "../img/down.png";
import last from "../img/last.png";
import {scalingX, scalingY} from "../globals";

function getRight(item){
    if(item?.caption.includes("von ")){
        return 20;
    }
    if(item?.caption.includes("Satz ")){
        return 20;
    }
    return 20;
}

function getBottom(item){
    if(item?.caption.includes("von ")){
        return 20;
    }
    if(item?.caption.includes("Satz ")){
        return 40;
    }
    if(item?.caption.includes("PFEILLINKS")){
        return 200;
    }
    if(item?.caption.includes("PFEILRECHTS")){
        return 80;
    }
    if(item?.caption.includes("PFEILRAUF")){
        return 160;
    }
    if(item?.caption.includes("PFEILRUNTER")){
        return 120;
    }
    return undefined;
}

export default function MasyStatus({masyGui, onAction, isMobil}){
    const arrows = {"JPG_PFEILLINKS": first, "JPG_PFEILRAUF": up, "JPG_PFEILRUNTER": down, "JPG_PFEILRECHTS": last};
    // if(isMobil)
    //   return (<div style={{position: "fixed", right: 20, bottom: 20}}>
    //     {masyGui.status?.map((item, i) =>
    //         item.caption.toString().includes("TYPE")? null:
    //       item.caption.startsWith("JPG") ?
    //           <img draggable={false} className={"icon"} src={arrows[item.caption.trim()]}  onClick={() => onAction("action: " + item.action)} />:
    //           <div style={{fontWeight: "bold", whiteSpace: "pre-wrap"}}
    //                onClick={() => onAction("action: " + item.action)}>{item.caption.split("_")[0]}</div>
    //     )
    //     }
    //   </div>)
    return (
        <div style={{position: "relative"}}>
            {masyGui.status?.map((item, i) =>
                item.caption.toString().includes("TYPE") ?
                    !isMobil && <div style={{
                        position: "absolute",
                        left: item.posy * scalingX,
                        top: item.posx * scalingY,
                        width: item.sizex * scalingX,
                        height: item.sizey * scalingY,
                        background: "lightgray",
                        borderColor: "white",
                        borderWidth: 2,
                        borderStyle: "solid"
                    }}
                                     key={"statusBg_" + i}
                    /> :
                    <div style={{
                        position: isMobil ? "fixed" : "absolute",
                        left: isMobil ? "auto" : item.posy * scalingX,
                        top: isMobil ? "auto" : item.posx * scalingY,
                        right: !isMobil ? "auto" : getRight(item),
                        bottom: !isMobil ? "auto" : getBottom(item),
                        display: "flex",
                        flexDirection: "row",
                        background: "white"
                    }}
                         key={"status_" + i}
                    >
                        {
                            item.caption.startsWith("JPG") ?
                                <img draggable={false} className={"icon"} src={arrows[item.caption.trim()]}
                                     onClick={() => onAction("action: " + item.action)} alt={"JPG"}/> :
                                <div style={{fontWeight: "bold", whiteSpace: "pre-wrap", padding: 3}}
                                     onClick={() => onAction("action: " + item.action)}>{item.caption.split("_")[0]}</div>
                        }

                    </div>
            )}
        </div>
    );
}