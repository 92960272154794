import "./style.css";
import {useEffect, useState} from "react";
import {overlayStyle} from "./MasySearchWindow";
import {masyReplace, scalingX, scalingY} from "../globals";
import MasyCloseButton from "./MasyCloseButton";

function buttons(e, data){
    data.buttons?.map((item, i) => {
        let search = item.type?.toString().toLowerCase().match("xbek_(alt_|ctrl_|sh_)?[a-z0-9]+"); //xbeK_F10 xbeK_ALT_F5 xbeK_CTRL_F2 xbeK_SH_F2
        if(search){
            let tiles = search[0].split("_");
            if(tiles[1].replace("esc", "escape") === e.key.toLowerCase() ||
                (((e.altKey && tiles[1] === "alt") || (e.shiftKey && tiles[1] === "sh") || (e.ctrlKey && tiles[1] === "ctrl"))
                    && tiles.length > 2
                    && tiles[2] === e.key.toLowerCase())){
                document.activeElement.blur();
                console.log("fire");
                document.getElementById("buttonSearch_" + i).click();
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        }
    });
}

export default function MasyTableWindow({data, setData, onAction, setModal, isMobil, setTooltip}){
    const [selected, setSelected] = useState([0, 0]);

    function onESC(){
        setData(null);
        onAction("action: {||webLoop := -1}");
    }

    function onOK(){
        onAction("action: {||DbSkip(" + selected[1] + ")}");
        setData(null);
        onAction("action: {||webLoop--}");
    }

    function onKey(e){
        console.log("search window event");
        if(e.key === "ArrowRight"){
            if(selected[0] < data.colHeader.length - 1){
                setSelected(arr => [arr[0] + 1, arr[1]]);
                //onAction("action: {||DbSkip("+selected[1]+")}")
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowLeft"){
            if(selected[0] > 0){
                setSelected(arr => [arr[0] - 1, arr[1]]);
                //onAction("action: {||DbSkip("+selected[1]+")}")
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowUp"){
            if(selected[1] > 0){
                setSelected(arr => [arr[0], arr[1] - 1]);
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowDown"){
            if(selected[1] < data.data.length - 1){
                setSelected(arr => [arr[0], arr[1] + 1]);
            }
            e.preventDefault();
        }
        else if(e.key === "Enter"){
            onOK();
            e.preventDefault();
        }
        else if(e.key === "Escape"){
            onESC();
            e.preventDefault();
        }
        else{
            buttons(e, data);
        }
        e.stopImmediatePropagation();
    }

    useEffect(() => {
        window.addEventListener("keydown", onKey, true);
        document.getElementById("input")?.focus();

        if(data.buffer === ""){
            setData(data => {
                data.buffer = null;
                return data;
            });
            setSelected(sel => [sel[0], 0]);
        }
        if(data.data && data.data.length <= selected[1] && selected[1] !== 0){
            setSelected(sel => [sel[0], 0]);
        }
        if(data.popup){
            setModal({text: data.popup});
            setData(data => {
                data.popup = null;
                return data;
            });
        }

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    }, [data, selected]);

    return (
        <div className={"backgroundBlur"}>
            <div style={overlayStyle(isMobil)}>
                <div className={"title"}>{data.title}</div>
                <div className={"toolbar"}>
                    {data.buttons?.map((button, i) =>
                        (button.action.includes("webLoop:=-1") || button.action === "onEsc") && !isMobil ?
                            <MasyCloseButton text={masyReplace(button.caption)} onAction={() => onESC()}/> :
                            <button
                                className={"button"}
                                disabled={!button.caption.includes("OK") && !button.caption.includes("Ende") && !button.caption.includes("Abbruch")}
                                style={{
                                    width: isMobil ? "auto" : button.sizex * scalingX,
                                    height: button.sizey * scalingY,
                                    margin: 10,
                                    opacity: !button.caption.includes("OK") && !button.caption.includes("Ende") && !button.caption.includes("Abbruch") ? 0.5 : 1,
                                    cursor: !button.caption.includes("OK") && !button.caption.includes("Ende") && !button.caption.includes("Abbruch") ? "not-allowed" : "pointer"
                                }}
                                onClick={() => {
                                    if(button.action.includes("webLoop--") || button.action === "onOk"){
                                        onOK();
                                    }
                                    else if(button.action.includes("webLoop:=-1") || button.action === "onEsc"){
                                        onESC();
                                    }
                                    else{
                                        onAction("action: {||DbSkip(" + selected[1] + ")}");
                                        onAction("action: " + button.action.replace("oBrowse:colPos", selected[0] + 1));
                                    }
                                }}
                                id={"buttonSearch_" + i}
                                key={"buttonSearch_" + i}
                            >
                                {masyReplace(button.caption)}
                            </button>
                    )}
                </div>
                <div style={{
                    gridTemplateColumns: data.colHeader?.map(() => "1fr").join(" ")
                }} className={"grid"}>
                    {data.colHeader?.map((col, i) =>
                        <div
                            className={"gridHeaderElement"}
                            style={{
                                borderRightWidth: data.colHeader.length - 1 === i ? 0 : 1
                            }}
                            onClick={() => setSelected([i, 0])}>{col}</div>
                    )}
                    {data.data?.map((row, y) =>
                        row.map((col, x) =>
                            <div
                                className={"gridElement"}
                                style={{
                                    borderRightWidth: data.colHeader.length - 1 == x ? 0 : 1,
                                    background: x === selected[0] && y === selected[1] ? "var(--primary)" : y === selected[1] ? "var(--secondary)" : y % 2 === 0 ? "white" : "var(--secondary30)"
                                }}
                                onMouseEnter={() => setTooltip(col)}
                                onMouseLeave={() => setTooltip(null)}
                                onClick={() => {
                                    if(selected[1] === y){
                                        setSelected([x, y]);
                                        // onAction("action: {||DbSkip("+y+")}")
                                    }
                                    else if(selected[0] === x){
                                        setSelected([x, y]);
                                    }
                                    else{
                                        setSelected([selected[0], y]);
                                    }
                                }}>{col}</div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}