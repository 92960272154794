import "./style.css";
import {useEffect, useState} from "react";
import {scalingX, scalingY} from "../globals";

export default function NewEntry({data, setData, onAction, isMobil}){
    const [value, setValue] = useState(data.input.value.trim());
    useEffect(() => {
        if(data?.input?.type.includes("99.99.99"))
            onAction("action: {||" + data.input.valuePointer + ":=ctod(\"" + new Date(value).toLocaleDateString('de-DE') + "\")}");
        else
            onAction("action: {||" + data.input.valuePointer + ":=\"" + value + "\"}");
    }, [value]);

    useEffect(() => {
        window.addEventListener("keydown", onKey, true);
        document.getElementById("inputNew").focus();

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    }, []);

    function onKey(e){
        console.log("new menu key " + e.key);
        data.buttons?.map((item, i) => {
            let search = item.type?.toString().toLowerCase().match("xbek_(alt_|ctrl_|sh_)?[a-z0-9]+"); //xbeK_F10 xbeK_ALT_F5 xbeK_CTRL_F2 xbeK_SH_F2
            if(search){
                let tiles = search[0].split("_");
                if(tiles[1].replace("esc", "escape") === e.key.toLowerCase() ||
                    (((e.altKey && tiles[1] === "alt") || (e.shiftKey && tiles[1] === "sh") || (e.ctrlKey && tiles[1] === "ctrl"))
                        && tiles.length > 2
                        && tiles[2] === e.key.toLowerCase())){
                    document.activeElement.blur();
                    document.getElementById("button_" + i).click();
                    e.preventDefault();
                }
            }
        });
        e.stopImmediatePropagation();
    }

    return (
        <div className={"backgroundBlur"}>
            <div style={{
                position: isMobil ? "fixed" : "absolute",
                width: isMobil ? "80%" : "520px",
                marginRight: "10%",
                marginLeft: "10%",
                marginTop: isMobil? 100: 0,
                background: "lightgray",
                height: "300px"
            }}>
                <div className={"title"}>{data.title}</div>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-evenly"}}>
                    {data.buttons?.map((button, i) =>
                        <button
                            className={"button"}
                            style={{
                                position: isMobil ? "block" : "absolute",
                                left: button.posy * scalingX,
                                top: button.posx * scalingY,
                                width: button.sizex * scalingX,
                                height: button.sizey * scalingY,
                                margin: isMobil ? 10 : 0
                            }}
                            onClick={() => {
                                onAction("action: " + button.action);
                                setData(null);
                            }}
                            id={"button_" + i}
                        >
                            {button.caption}
                        </button>
                    )}
                    <div style={{
                        position: isMobil ? "block" : "absolute",
                        top: data.input.posx * scalingY,
                        left: data.input.posy * scalingX
                    }}>
                        {data.input.caption}
                        <input onChange={e => setValue(e.target.value)} value={value} id={"inputNew"}/>
                    </div>
                </div>
            </div>
        </div>
    );
}