import {Dropdown, ButtonToolbar} from 'rsuite';
import "./style.css";
import {useEffect, useState} from "react";
import {masyReplace} from "../globals";

function active(item, i, j){
    return item.caption.includes("Indexsuche") || item.caption.includes("Standardsuche") || (i?.startsWith("B") && i?.includes("ttern") && j < 6) || item.action.includes("MASY_02RUN");
}

export default function MasyDropdownMenu({masyGui, onClick}){
    const menus = ["menu", "menu1", "menu2", "menu3", "menu4", "menu4a", "menu5", "menu6", "menuF9", "menu7", "menu8", "menu9"];
    const [open, setOpen] = useState(-1);
    const [open1, setOpen1] = useState(-1);

    useEffect(() => {
        window.addEventListener("keydown", onKey, true);

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    }, [masyGui, open, open1]);

    function onKey(e){
        console.log("drop menu key " + e.key);
        if(e.altKey){
            menus.map((menu, i) => {
                if(masyGui?.[menu]?.caption?.includes("&") &&
                    masyGui[menu].caption[masyGui[menu].caption.search("&") + 1].toLowerCase() === e.key){
                    // console.log("drop_" + i);
                    document.getElementById("drop_" + i).click();
                    e.stopImmediatePropagation();
                }
            });
            e.preventDefault();
        }
        if(open > -1 && open1 < 0 && !e.altKey){
            masyGui[menus[open]].menu.map((item, j) => {
                if(item.caption.includes("&") && item.caption[item.caption.search("&") + 1].toLowerCase() === e.key){
                    // console.log("drop_" + open + "_" + j);
                    document.getElementById("drop_" + open + "_" + j)?.click();
                }
            });
            e.stopImmediatePropagation();
            e.preventDefault();
        }
        if(open > -1 && open1 > -1 && !e.altKey){
            masyGui[menus[open]].menu.map((item, j) => {
                if(item.caption.includes("&") && item.caption[item.caption.search("&") + 1].toLowerCase() === e.key){
                    // console.log("drop_" + open + "_" + open1 + "_" + j);
                    document.getElementById("drop_" + open + "_" + open1 + "_" + j)?.click();
                }
            });
            e.stopImmediatePropagation();
            e.preventDefault();
        }
    }

    return (
        <ButtonToolbar>
            {menus.map((menu, i) => {
                return masyGui?.[menu]?.caption?.toLowerCase().includes("masywin") || masyGui?.[menu]?.caption?.toLowerCase().includes("suche") ? (masyGui?.[menu]?.caption ?
                    <Dropdown title={masyReplace(masyGui?.[menu]?.caption)} key={"drop_" + i} id={"drop_" + i}
                              onOpen={() => {
                                  setTimeout(() => setOpen(i), 100); //if not 0 need to wait for other to close else new opens old closes
                              }} onClose={() => setOpen(prev => prev !== i ? prev : -1)}>
                        {masyGui?.[menu]?.menu?.map((item, j) =>
                            item.menu ?
                                <Dropdown title={masyReplace(item.caption)} key={"drop_" + i + "_" + j}
                                          id={"drop_" + i + "_" + j}
                                          placement={"rightStart"} trigger={["click", "hover"]}
                                          onOpen={() => setOpen1(j)}
                                          onClose={() => setOpen1(-1)}>
                                    {item.menu.map((subItem, k) =>
                                        subItem.menu ?
                                            <Dropdown.Menu title={masyReplace(subItem.caption)}
                                                           key={"drop_" + i + "_" + j + "_" + k}>
                                                {subItem.menu.map((subsubItem, l) =>
                                                    subsubItem.menu ?
                                                        <Dropdown.Menu title={masyReplace(subsubItem.caption)}
                                                                       key={"drop_" + i + "_" + j + "_" + k + "_" + l}>
                                                        </Dropdown.Menu> :
                                                        <Dropdown.Item
                                                            onClick={() => onClick("action: " + item.action)}
                                                            key={"drop_" + i + "_" + j + "_" + k + "_" + l}
                                                            id={"drop_" + i + "_" + j + "_" + k + "_" + l}
                                                        >{masyReplace(subsubItem.caption)}</Dropdown.Item>
                                                )}
                                            </Dropdown.Menu> :
                                            <Dropdown.Item
                                                disabled={i !== 0}
                                                onClick={() => onClick("action: " + subItem.action)}
                                                key={"drop_" + i + "_" + j + "_" + k}
                                                id={"drop_" + i + "_" + j + "_" + k}
                                            >{masyReplace(subItem.caption)}</Dropdown.Item>
                                    )}
                                </Dropdown> :
                                item.caption === "SEPARATOR" ?
                                    <Dropdown.Separator
                                        key={"drop_" + i + "_" + j} id={"drop_" + i + "_" + j}
                                    /> :
                                    <Dropdown.Item
                                        disabled={!active(item, masyGui?.[menu]?.caption, j)}
                                        onClick={() => !active(item, masyGui?.[menu]?.caption, j) ? console.log("not yet supported " + item.action) : onClick("action: " + item.action)}
                                        key={"drop_" + i + "_" + j} id={"drop_" + i + "_" + j}
                                    >{masyReplace(item.caption)}</Dropdown.Item>
                        )
                        }
                    </Dropdown> :
                    null) : null;
            })}
        </ButtonToolbar>
    );
}