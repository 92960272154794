import "./style.css";
import close from "../img/close.png"

export default function MasyCloseButton({text, onAction}){

    return (
        <button style={{position: "absolute", top: 0, right: 10, background: "white", height: 52}} onClick={onAction}>
            <img src={close} style={{height: "100%", width: 52}}/>
            <label style={{color: "black", marginTop: -5, display: "block"}}>{text}</label>
        </button>
    );
}