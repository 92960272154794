import "./style.css";
import {useEffect, useState} from "react";
import MasySearchWindowF2 from "./MasySearchWindowF2";
import MasySearchShow from "./MasySearchShow";
import MasySwitchButton from "./MasySwitchButton";
import {masyReplace, scalingX, scalingY} from "../globals";
import MasyCloseButton from "./MasyCloseButton";

function buttons(e, data){
    data.buttons?.map((item, i) => {
        let search = item.type?.toString().toLowerCase().match("xbek_(alt_|ctrl_|sh_)?[a-z0-9]+"); //xbeK_F10 xbeK_ALT_F5 xbeK_CTRL_F2 xbeK_SH_F2
        if(search){
            let tiles = search[0].split("_");
            if(tiles[1].replace("esc", "escape") === e.key.toLowerCase() ||
                (((e.altKey && tiles[1] === "alt") || (e.shiftKey && tiles[1] === "sh") || (e.ctrlKey && tiles[1] === "ctrl"))
                    && tiles.length > 2
                    && tiles[2] === e.key.toLowerCase())){
                document.activeElement.blur();
                console.log("fire");
                document.getElementById("buttonSearch_" + i).click();
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        }
    });
}

export function overlayStyle(isMobil){
    if(isMobil){
        return {
            position: "absolute",
            width: "100%",
            maxHeight: "calc(100% - 50px)",
            background: "white",
            overflow: "scroll",
            zIndex: 10,
            marginTop: 50,
            top: 0,
            left: 0
        };
    }
    return {
        position: "absolute",
        width: "95%",
        height: "95%",
        maxHeight: "100%",
        marginRight: "10%",
        marginLeft: "2%",
        marginTop: "2%",
        background: "white",
        zIndex: 10
    };
}

export default function MasySearchWindow({data, setData, onAction, setModal, isMobil, setTooltip}){
    const [query, setQuery] = useState("");
    const [selected, setSelected] = useState([0, 0]);
    const [searchF2, setSearchF2] = useState(false);
    const [show, setShow] = useState(false);

    function onESC(){
        if(searchF2){
            setSearchF2(false);
            let tmp = data;
            tmp.searchF2title = null;
            setData(tmp);
        }
        else if(show){
            setShow(false);
            let tmp = data;
            tmp.showtitle = null;
            setData(tmp);
        }
        else{
            setData(null);
        }
        onAction("action: {||webLoop := -1}");
    }

    function onOK(row){
        if(searchF2){
            onAction("action: {||DbSkip(" + row + ")}");
            setSearchF2(false);
            let tmp = data;
            tmp.searchF2title = null;
            setData(tmp);
            onAction("action: {||webLoop--}");
            onAction("action: {||sendBrowseData(20)}");

        }
        else if(show){
            setShow(false);
            data.showtitle = null;
            onAction("action: {||webLoop--}");
            onAction("action: {||sendBrowseData(20)}");
            setSelected(arr => [arr[0], 0]);
        }
        else{
            onAction("action: {||DbSkip(" + selected[1] + ")}");
            setData(null);
            onAction("action: {||webLoop--}");
        }
    }

    function onKey(e){
        console.log("search window event");
        if(e.key === "ArrowRight" && !searchF2 && (data.type === "index" || data.type === "table")){
            if(selected[0] < data.colHeader.length - 1){
                setSelected(arr => [arr[0] + 1, arr[1]]);
                onAction("action: {||DbSkip(" + selected[1] + ")}");
                setQuery("");
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowLeft" && !searchF2 && (data.type === "index" || data.type === "table")){
            if(selected[0] > 0){
                setSelected(arr => [arr[0] - 1, arr[1]]);
                onAction("action: {||DbSkip(" + selected[1] + ")}");
                setQuery("");
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowUp" && !searchF2){
            if(selected[1] > 0){
                setSelected(arr => [arr[0], arr[1] - 1]);
            }
            e.preventDefault();
        }
        else if(e.key === "ArrowDown" && !searchF2){
            if(selected[1] < data.data.length - 1){
                setSelected(arr => [arr[0], arr[1] + 1]);
            }
            e.preventDefault();
        }
        else if(e.key === "Enter" && !searchF2){
            onOK();
            e.preventDefault();
        }
        else if(e.key === "Escape" && !searchF2){
            onESC();
            e.preventDefault();
        }
        else{
            buttons(e, data);
        }
        e.stopImmediatePropagation();
    }

    useEffect(() => {
        window.addEventListener("keydown", onKey, true);
        document.getElementById("input")?.focus();

        if(data.buffer === ""){
            setData(data => {
                data.buffer = null;
                return data;
            });
            setSelected(sel => [sel[0], 0]);
        }
        if(data.data && data.data.length <= selected[1] && selected[1] !== 0){
            setSelected(sel => [sel[0], 0]);
        }
        if(data.searchF2title){
            setSearchF2(true);
        }
        if(data.showtitle){
            setShow(true);
        }
        if(data.popup){
            setModal({text: data.popup});
            setData(data => {
                data.popup = null;
                return data;
            });
        }

        return () => {
            window.removeEventListener("keydown", onKey, true);
        };
    }, [data, selected, searchF2]);

    function doSearch(){
        if(data.type === "index"){
            onAction("action: {||SEK_TABW1(NIL,NIL,NIL,NIL,NIL,\"" + query + "\",\"" + selected[0] + "\")}");
        }
        if(data.type === "table"){
            onAction("action: {||SEK_TABWZ(NIL,NIL,NIL,NIL,NIL,\"" + query + "\",\"" + selected[0] + "\")}");
        }
    }

    useEffect(() => {
        doSearch();
    }, [query, selected]);

    useEffect(() => {
        if(data.type === "standard"){
            onAction("action: {||SEK_TABX1(NIL,NIL,NIL,NIL,NIL,\"" + query + "\")}");
        }
    }, [query]);

    function onClickAction(button){
        if(button.action.includes("DC_ReadGuiEvent(6") || button.action === "onOk"){
            onOK();
        }
        else if(button.action.includes("DC_ReadGuiEvent(5") || button.action === "onEsc"){
            onESC();
        }
        else{
            onAction("action: {||DbSkip(" + selected[1] + ")}");
            onAction("action: " + button.action.replace("oBrowse:colPos", selected[0] + 1));
        }
    }

    return (
        <div className={"backgroundBlur"}>
            <div style={overlayStyle(isMobil)}>
                {searchF2 &&
                    <MasySearchWindowF2 data={data} onESC={onESC} onOK={onOK} onAction={onAction} setData={setData}
                                        isMobil={isMobil} setTooltip={setTooltip}/>}
                {show && <MasySearchShow data={data} onESC={onESC} onOK={onOK} onAction={onAction} isMobil={isMobil}
                                         setTooltip={setTooltip}/>}
                <div className={"title"}>{data.title}</div>
                <div className={"toolbar"}>
                    <input onChange={e => setQuery(e.target.value)} value={query} id={"input"}
                           autoComplete={"off"} placeholder={"Suche nach " + data.for} className={"searchInput"}/>
                    {data.buttons?.map((button, i) =>
                        button.caption.includes("Suchmodus") ?
                            <MasySwitchButton
                                checked={data.modus ? !data.modus.includes("ungenau") : !button.caption.includes("ungenau")}
                                onChange={() => {
                                    onClickAction(button);
                                    doSearch();
                                }}
                                label={"Suchmodus"}
                                on={"genau"}
                                off={"ungenau"}
                            /> :
                            (button.action.includes("DC_ReadGuiEvent(5") || button.action === "onEsc") && !isMobil ?
                                <MasyCloseButton text={masyReplace(button.caption)} onAction={() => onClickAction(button)}/> :
                                <button
                                    className={"button " + (button.caption.includes("weitersuchen") && !data.colHeaderF2?.[selected[0]] && "buttonInactive")}
                                    disabled={button.caption.includes("weitersuchen") && !data.colHeaderF2?.[selected[0]]}
                                    style={{
                                        width: isMobil ? "auto" : button.sizex * scalingX,
                                        height: button.sizey * scalingY,
                                        cursor: button.caption.includes("weitersuchen") && !data.colHeaderF2?.[selected[0]] ? "not-allowed" : "pointer"
                                    }}
                                    onClick={() => onClickAction(button)}
                                    id={"buttonSearch_" + i}
                                    key={"buttonSearch_" + i}
                                >
                                    {masyReplace(button.caption)}
                                </button>
                    )}
                </div>
                <div style={{
                    gridTemplateColumns: data.colHeader?.map(() => "1fr").join(" ")
                }} className={"grid"}>
                    {data.colHeader?.map((col, i) =>
                        <div
                            className={"gridHeaderElement"}
                            style={{
                                borderRightWidth: data.colHeader.length - 1 === i ? 0 : 1
                            }}
                            onClick={() => setSelected([i, 0])}>{col}</div>
                    )}
                    {data.data?.map((row, y) =>
                        row.map((col, x) =>
                            <div
                                className={"gridElement"}
                                style={{
                                    borderRightWidth: data.colHeader.length - 1 == x ? 0 : 1,
                                    background: x === selected[0] && y === selected[1] ? "var(--primary)" : y === selected[1] ? "var(--secondary)" : y % 2 === 0 ? "white" : "var(--secondary30)"
                                }}
                                onMouseEnter={() => setTooltip(col)}
                                onMouseLeave={() => setTooltip(null)}
                                onClick={() => {
                                    if(selected[1] === y && (data.type === "index" || data.type === "table")){
                                        setSelected([x, y]);
                                        onAction("action: {||DbSkip(" + y + ")}");
                                        setQuery("");
                                    }
                                    else if(selected[0] === x){
                                        setSelected([x, y]);
                                    }
                                    else{
                                        setSelected([selected[0], y]);
                                    }
                                }}>{col}</div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}