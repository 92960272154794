export default function MasyWebSocket(setMasyGui,
                                      sendToMasyRef,
                                      setMasyGuiFields,
                                      setLoggedIn,
                                      setIsMasy2,
                                      setIsConnected,
                                      setStats,
                                      setSearch,
                                      setModal,
                                      setEditing,
                                      setNewEntry,
                                      setTable,
                                      isMobil,
                                      setIsLoading)
{

    const socket = new WebSocket("wss://masyweb.localisa.app:81/MasyWebSocket");

    // Connection opened
    socket.addEventListener("open", event => {
        console.log("opened websocket");
        sendToMasyRef.current = sendToMasy;
        setIsConnected(true);
        setIsLoading(false)

        let creds =  localStorage.getItem("creds")
        if (creds)
            creds = atob(creds)

        if(window.location.pathname.includes("MasyMagic")){
            const urlParams = new URLSearchParams(window.location.search);
            sendToMasy("login2: " + JSON.stringify({passcode: window.name, username: urlParams.get("username")}), true);
            setIsLoading(true)
            setIsMasy2(true);
        } else if(creds){
            sendToMasy("login: " + creds, true)
            setIsLoading(true)
        }
    });

    socket.addEventListener("close", event => {
        setIsConnected(false);
        if(window.location.pathname.includes("MasyMagic")){
            window.close();
        }
        else{
            setLoggedIn(false);
        }
    });

    // Listen for messages
    socket.addEventListener("message", event => {
        setIsLoading(false)
        let data = event.data;
        console.log(data);
        if(data.includes("Login erfolgreich.")){
            setLoggedIn(true);
            sendToMasy("load", true); //starts loading masy
        }
        else if(data.includes("error:")){
            data = JSON.parse(data.split("error:")[1]);
            setModal({
                text: data.message,
                okFun: data.message.includes("bereits eingeloggt") ? () => {
                    setLoggedIn(false);
                    setModal(null);
                } : null
            });
        }
        else if(data.includes("confirm:")){
            data = JSON.parse(data.split("confirm:")[1]);
            setModal({
                text: data.message,
                ok: "JA",
                abort: "NEIN",
                okFun: (() => {
                    sendToMasy("action: {||webLoop--}");
                    setModal(null);
                }),
                abortFun: (() => {
                    sendToMasy("action: {||webLoop:=-1}");
                    setModal(null);
                })
            });
        }
        else if(data.startsWith("close")){
            window.close();
            window.location.replace("/")
        }
        else if(data.startsWith("stats:")){
            setStats(JSON.parse(data.split("stats:")[1]));
        }
        else if(data.startsWith("MasyGui:")){
            data = JSON.parse(data.split("MasyGui:")[1]);
            if(Object.keys(data).length){
                setMasyGui(data);
            }
            if(data.fields){
                setMasyGuiFields(data.fields);
            }
        }
        else if(data.startsWith("newWindow:")){
            data = JSON.parse(data.split("newWindow:")[1]);
            let newWindow;
            if(isMobil){
                window.name = data.passcode;
                window.location.replace('/MasyMagic?username=' + data.username);
            }
            else{
                newWindow = window.open('/MasyMagic?username=' + data.username, data.passcode, 'width=1280,height=1000');
            }
            if(newWindow){
                newWindow.focus();
            }
        }
        else if(data.startsWith("updateField")){
            data = JSON.parse(data.split("updateField:")[1]);
            setMasyGuiFields((fields) => fields.map((field) => {
                field.value = field.action.trim() === data.name?.trim() ? data.value : field.value;
                return field;
            }));
        }
        else if(data.startsWith("edit:")){
            data = JSON.parse(data.split("edit:")[1]);
            if(data.message === "done"){
                setEditing(false);
                return;
            }
            else{
                setEditing(true);
            }
            setMasyGuiFields(data.fields);
            setMasyGui(gui => {
                gui.editButtons = data.buttons;
                return gui;
            });
        }
        else if(data.startsWith("new:")){
            data = JSON.parse(data.split("new:")[1]);
            console.log(data);
            setNewEntry(data);
        }
        else if(data.startsWith("updateSatz")){
            data = JSON.parse(data.split("updateSatz:")[1]);
            setMasyGui((masyGui) => {
                masyGui.status?.map((item) => {
                    if(item.caption.startsWith("Satz")){
                        item.caption = "Satz " + data[0];
                    }
                    if(item.caption.startsWith("von")){
                        item.caption = "von " + data[1];
                    }
                    return item;
                });
                return masyGui;
            });
        }
        else if(data.startsWith("sortedBy")){
            data = JSON.parse(data.split("sortedBy:")[1]);
            setMasyGui((masyGui) => {
                masyGui.sortedBy = data.toString()
                return masyGui;
            });
        }
        else if(data.startsWith("search:")){
            data = JSON.parse(data.split("search:")[1]);
            setSearch(search => search ?
                {...search, ...data} :
                data
            );
        }
        else if(data.startsWith("table:")){
            data = JSON.parse(data.split("table:")[1]);
            setTable(table => table ?
                {...table, ...data} :
                data
            );
        }
        //console.log(JSON.parse(data.split(/:(.*)/s)[1]))
        //console.log(data)
    });

    function sendToMasy(text, plane = false){
        let tmp = text;
        if(!plane){
            tmp = tmp.replaceAll("\"", "'");
        }
        console.log("send: " + tmp);
        if(!text.includes("2RUNW")){
            socket.send(tmp);
        }
        else{
            socket.send(tmp);
        }
    }

}

