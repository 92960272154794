import "./style.css";
import Switch from "react-switch";

export default function MasySwitchButton({checked, label, off, on, onChange}){

    return (
        <div style={{display: "flex", flexDirection: "column", width: "fit-content", margin: 10}}>
            <label>{label}</label>
            <div style={{display: "flex", flexDirection: "row", width: "fit-content"}} className={"centerVertical"}>
                <div className={"value"} style={{paddingLeft: 0, opacity: !checked?"1":"0.5"}}>{off}</div>
                <Switch onChange={(checked) => onChange(checked)} checked={checked} onColor={"#5E9D95"}
                        checkedIcon={null} uncheckedIcon={null}/>
                <div className={"value"} style={{opacity: checked?"1":"0.5"}}>{on}</div>
            </div>
        </div>
    );
}