import {useEffect, useState} from "react";

export function MasyHover({tooltip}){
    const [mouse, setMouse] = useState({x: -1, y: -1})

    useEffect(()=>{
        window.addEventListener("mousemove", (e)=>setMouse({x: e.clientX, y: e.clientY + 10}))
    },[])

    if(tooltip && mouse.x > 0 && mouse.y > 0){
        return (<div
            style={{
                position: "absolute",
                background: "var(--darkgray)",
                zIndex: 10,
                borderRadius: 6,
                textAlign: "center",
                padding: 5,
                color: "white",
                top: mouse.y,
                left: mouse.x
        }}
        >
            {tooltip}
        </div>);
    }
    else{
        return null
    }
}
