import "./style.css";
import {useEffect} from "react";
import logo from "../img/MASYWIN.PNG";
import {scalingX, scalingY} from "../globals";

export default function MasyMenu({masyGui, onAction, isMobil, stats, setStats}){

    let width = 0;
    let height = 0;
    masyGui.mainmenu?.map((item) => {
        if(item.col * scalingX + item.sizex * scalingX > width){
            width = item.col * scalingX + item.sizex * scalingX;
        }
        if(item.row * scalingY + item.sizey * scalingY > height){
            height = item.row * scalingY + item.sizey * scalingY;
        }
    });

    useEffect(() => {
        window.addEventListener("keydown", onKey, false);

        return () => {
            window.removeEventListener("keydown", onKey, false);
        };
    }, [masyGui]);

    function onKey(e){
        console.log("main menu key " + e.key);
        masyGui.mainmenu?.map((item) => {
            if(item.caption.split(" ")[0].length === 1 && item.caption.split(" ")[0].toLowerCase() === e.key){
                onAction("action: " + item.action);
                e.stopImmediatePropagation();
            }
        });
    }

    return (
        <div style={{height: "90vh", width: "100vw"}} className={isMobil ? "" : "centerVertical"}>
            <div style={{
                position: "relative",
                width: isMobil ? "100%" : width,
                height: height,
                marginRight: "auto",
                marginLeft: "auto"
            }}>
                <img alt={"Masywin Logo"} style={{
                    top: "4%",
                    width: "10%",
                    minWidth: 200,
                    marginTop: isMobil ? "10%" : "-10%",
                    marginBottom: "10%"
                }} src={logo} onClick={() => stats || isMobil ? setStats(null) : onAction("stats", true)}/>
                {masyGui.mainmenu?.map((item) => {
                        if(item.caption.includes("beenden")){
                            return <div
                                style={{
                                    textDecoration: "underline",
                                    color: "var(--primary)",
                                    marginBottom: 10,
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: item.sizex * scalingX,
                                    height: item.sizey * scalingY,
                                    position: isMobil ? "block" : "absolute",
                                    left: item.col * scalingX,
                                    top: item.row * scalingY,
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    localStorage.clear()
                                    onAction("action: " + item.action);
                                }}
                                key={"item_" + item.caption}>Beenden</div>;
                        }
                        return (<div style={{
                            marginBottom: 10,
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: item.sizex * scalingX,
                            height: item.sizey * scalingY,
                            position: isMobil ? "block" : "absolute",
                            left: item.col * scalingX,
                            top: item.row * scalingY,
                            opacity: item.caseN < 4? 1: 0.5,
                            cursor: item.caseN < 4? 'pointer': 'not-allowed'
                        }}
                                     className={"centerVertical"}
                                     onClick={() => item.caseN < 4?onAction("action: " + item.action): null}
                                     key={"item_" + item.caption}>
                            <div className={"dot"}><h5> {item.caption.split(" ")[0]}</h5></div>
                            <button className={"menuItem"} style={{
                                paddingLeft: 70,
                                width: "100%",
                                height: "80%",
                                cursor: item.caseN < 4? 'pointer': 'not-allowed'
                            }}
                            > {item.caption.split(" ")[1]}</button>
                        </div>);
                    }
                )}
            </div>
        </div>
    );
}