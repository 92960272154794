import "./style.css";
import {scalingX, scalingY} from "../globals";

export default function MasyZusatz({masyGui, onAction}){

    return (
        <div style={{position: "relative"}}>
            {masyGui.zusatz?.map((item) =>
                item.caption.toString().includes("TYPE") ?
                    <div style={{
                        position: "absolute",
                        left: item.posy * scalingX,
                        top: item.posx * scalingY,
                        width: item.sizex * scalingX,
                        height: item.sizey * scalingY,
                        background: "lightgray",
                        borderColor: "white",
                        borderWidth: 2,
                        borderStyle: "solid"
                    }}/> :
                    <div style={{
                        position: "absolute",
                        left: item.posy * scalingX,
                        top: item.posx * scalingY,
                        display: "flex",
                        flexDirection: "row"
                    }}>
                        <div style={{fontWeight: "bold", whiteSpace: "pre-wrap"}}>{item.caption}</div>
                    </div>
            )}
        </div>
    );
}