import {slide as Menu} from 'react-burger-menu';
import {masyReplace} from "../globals";
import {useState} from "react";

export default function MasyBurgerMenu({masyGui, onClick, isMasy2}){
    const menus = ["menu", "menu1", "menu2", "menu3", "menu4", "menu4a", "menu5", "menu6", "menuF9", "menu7", "menu8", "menu9"];
    const [open, setOpen] = useState(-1);
    const [open1, setOpen1] = useState(-1);
    const [isOpen, setIsOpen] = useState(false);

    var styles = {
        bmBurgerButton: {
            position: 'fixed',
            width: '36px',
            height: '30px',
            left: isMasy2?"15px":'36px',
            top: isMasy2?"10px":'36px'
        },
        bmBurgerBars: {
            background: "var(--primary)"
        },
        bmBurgerBarsHover: {
            background: '#a90000'
        },
        bmCrossButton: {
            height: '48px',
            width: '48px'
        },
        bmCross: {
            background: "var(--red)"
        },
        bmMenuWrap: {
            position: 'fixed',
            height: '100%'
        },
        bmMenu: {
            background: '#373a47',
            fontSize: '1.15em',
            display: "flex",
            alignItems: "center"
        },
        bmMorphShape: {
            fill: "var(--darkgray)"
        },
        bmItemList: {
            padding: '0.8em',
            display: "flex",
            flexDirection: "column",
            height: "auto",
            width: "100%",
            maxHeight: "90%"
        },
        bmItem: {
            color: "var(--primary)",
            minHeight: 75,
            borderStyle: "solid",
            borderRight: "none",
            borderLeft: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        },
        bmOverlay: {
            background: "var(--darkgray20)"
        }
    };

    function active(item){
        if((open === 0 && open1 > -1) || item.action.includes("MASY_02RUN"))
            return true
        return item.caption.includes("Indexsuche") || item.caption.includes("Standardsuche");
    }

    function action(menu){
        if(active(menu)){
            console.log("asd")
            setOpen1(-1)
            setOpen(-1)
            setIsOpen(false)
            onClick("action: " + menu.action);
        }
    }

    return (
        <Menu
            isOpen={isOpen}
            styles={styles}
            onClose={() => {
                if(open1 > -1){
                    setOpen1(-1);
                }
                else if(open > -1){
                    setOpen(-1);
                }
                else{
                    setIsOpen(false);
                }
            }}
            onStateChange={(state) => setIsOpen(state.isOpen)}
        >
            {
                open < 0 ?
                    menus.map((menu, i) =>
                        masyGui?.[menu]?.menu && (masyGui?.[menu]?.caption.toLowerCase().includes("suche") || masyGui?.[menu]?.caption.includes("MASYWIN")) ?
                            masyGui?.[menu]?.caption ?
                                <div
                                    style={{borderTop: i === 0 ? "solid" : "none"}}
                                    onClick={() => {
                                        setOpen(i);
                                    }}>
                                    {masyReplace(masyGui?.[menu]?.caption)}
                                </div> :
                                null :
                            menu.caption ?
                                <div style={{borderTop: i === 0 ? "solid" : "none", color: active(menu)? "white": "black"}}
                                     onClick={()=>action(menu)}>
                                    {masyReplace(menu?.caption)}
                                </div> :
                                null
                    ) :
                    open1 < 0 ?
                        masyGui[menus[open]]?.menu.map((menu, i) =>
                            menu?.menu ?
                                menu.caption ?
                                    <div
                                        style={{borderTop: i === 0 ? "solid" : "none"}}
                                        onClick={() => {
                                            setOpen1(i);
                                        }}>
                                        {masyReplace(menu?.caption)}
                                    </div> :
                                    null :
                                menu.caption ?
                                    <div style={{borderTop: i === 0 ? "solid" : "none", color: active(menu)? "white": "black"}}
                                         onClick={()=>action(menu)}>
                                        {masyReplace(menu?.caption)}
                                    </div> :
                                    null
                        ) :
                        masyGui[menus[open]]?.menu[open1]?.menu.map((menu, i) =>
                            menu?.menu ?
                                menu.caption ?
                                    <div
                                        style={{borderTop: i === 0 ? "solid" : "none"}}
                                        onClick={() => {
                                            setOpen(i);
                                        }}>
                                        {masyReplace(menu?.caption)}
                                    </div> :
                                    null :
                                menu.caption ?
                                    <div style={{borderTop: i === 0 ? "solid" : "none", color: active(menu)? "white": "black"}}
                                         onClick={()=>action(menu)}>
                                        {masyReplace(menu?.caption)}
                                    </div> :
                                    null
                        )

            }
        </Menu>
    );
}
