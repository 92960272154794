import "./style.css";
import MasySearchWindow from "./MasySearchWindow";
import {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import MasyTableWindow from "./MasyTableWindow";
import {scalingX, scalingY} from "../globals";

export default function MasyFields({
                                       fields,
                                       search,
                                       setSearch,
                                       onAction,
                                       setModal,
                                       style,
                                       setFields,
                                       editing,
                                       table,
                                       setTable,
                                       sortedBy,
                                       isMobil,
                                       onDblClick,
                                       activeField,
                                       setActiveField,
                                       reFocusRef,
                                       setTooltip
                                   }){
    const colorsEdit = {"C": "lightblue", "D": "lightgreen", "N": "lightpink"};
    const [selectDate, setSelectDate] = useState(null);
    const [focus, setFocus] = useState(null);

    function updateValue(action, value, i){
        setFocus(i);
        setFields(fields => fields.map((field) => {
            field.value = field.action.trim() === action.trim() ? value : field.value;
            return field;
        }));
    }

    useEffect(() => {
        if(window.innerWidth === 1280){
            let height = 1000;
            let width = 1280;
            fields?.map((item) => {
                let width_ = item.fieldPosx * scalingX + (item.fieldSizex ? item.fieldSizex : item.bmp ? ((item.bmp.toString().trim().length + 2) * scalingX) : (item.value.toString().length * 6));
                let height_ = item.fieldPosy * scalingY + (item.fieldSizey ? item.fieldSizey : 5) * scalingY;
                if(height_ > height){
                    height = height_;
                }
                if(width_ > width){
                    width = width_;
                }
            });
            window.resizeTo(width, height + 30); //30 ist windows leite ca
        }
    }, [fields]);

    useEffect(() => {
        if(reFocusRef){
            reFocusRef.current = () => document.getElementById("input_" + focus)?.focus();
        }
    }, [focus]);

    useEffect(() => {
        if(editing){
            let j = activeField ?? 0;
            while(!document.getElementById("input_" + (j)) && j < fields.length)
                j++;
            document.getElementById("input_" + j)?.focus();
            setActiveField(null);
        }
    }, [editing]);

    useEffect(() => {
        if(!search){
            document.getElementById("input_" + focus)?.focus();
        }
    }, [search]);

    function onFocus(item, i){
        return e => {
            if(item.type === "D"){
                setSelectDate(i);
            }
            else{
                setSelectDate(null);
            }
            if(editing && item.valuePointer && item.gotFocus && !search){
                onAction("action: " + item.gotFocus);
            }
        };
    }

    function onBlur(item){
        return e => {
            if(editing && item.valuePointer && !search){
                let value = item.value;
                if(item.value.toString().trim() !== e.target.value.toString().trim()){
                    value = e.target.value;
                }
                if(item.type === "N"){
                    onAction("action: {||edt_feld:='" + item.valuePointer + "',&edt_feld:=val('" + value + "')}");
                }
                else if(item.type === "D"){
                    onAction("action: {||edt_feld:='" + item.valuePointer + "',&edt_feld:=ctod('" + new Date(value).toLocaleDateString('de-DE') + "')}");
                }
                else{
                    onAction("action: {||edt_feld:='" + item.valuePointer + "',&edt_feld:='" + value + "'}");
                }
                if(item.validate.includes("||") && item.lostFocus.includes("||")){
                    onAction("action: " + item.validate.split("}")[0] + ", " + item.lostFocus.split("||")[1]);
                    return;
                }
                if(item.validate){
                    onAction("action: " + item.validate);
                }
                if(item.lostFocus){
                    onAction("action: " + item.lostFocus);
                }
            }
        };
    }

    function onKeydown(i){
        return e => {
            let j = 1;
            if(e.key === "Enter" && !e.shiftKey){
                while(!document.getElementById("input_" + (i + j)) && i + j < fields.length)
                    j++;
                document.getElementById("input_" + (i + j))?.focus();
                e.stopPropagation();
            }
        };
    }

    return (
        <div style={{
            position: "relative",
            display: isMobil ? "flex" : "block",
            flexWrap: "wrap",
            maxWidth: "100%", ...style
        }}>
            {((!search && !table && isMobil) || !isMobil) && fields?.map((item, i) =>
                <div style={{
                    position: isMobil ? "block" : "absolute",
                    left: item.posy * scalingX,
                    top: (item.posx - 4) * scalingY,//todo posy, 4 is offset für toolbar und co desmasy hat
                    display: "flex",
                    textAlign: "left",
                    flexDirection: "row",
                    margin: "15px 7px 0px 7px",
                    maxWidth: "100%"
                    // background: editing && item.valuePointer ? colorsEdit[item.type] ?? "black" : "none"
                }}
                     key={"field_" + item.action}
                     id={"field_" + i}
                     onKeyDown={onKeydown(i)}
                >
                    <div>
                        <label style={{
                            color: "var(--primary)",
                            opacity: !item.index || item.index === sortedBy || (!sortedBy && item.index === "1") ? 1 : 0.5,
                            fontWeight: item.index ? "bold" : "normal",
                            whiteSpace: "nowrap"
                        }}
                        >
                            {item.caption.replace(/\s*:/, ":")}
                        </label>
                        {item.mandatory && <label> *</label>}
                    </div>
                    {editing && item.valuePointer ?
                        item.type === "M" ?
                            <textarea value={item.value.toString().replace(/\s+$/, ' ')}
                                      style={{
                                          width: item.fieldSizex * scalingX,
                                          height: item.fieldSizey * scalingY,
                                          position: isMobil ? "block" : "absolute",
                                          left: item.fieldPosx ? (item.fieldPosx - item.posy) * scalingX : item.caption.length * 6,
                                          top: item.fieldPosy ? (item.fieldPosy - item.posx) * scalingY : undefined
                                      }}
                                      onFocus={onFocus(item, i)}
                                      onBlur={onBlur(item)}
                                      onChange={(e) => editing && item.valuePointer && updateValue(item.action, e.target.value, i)}
                                      id={"input_" + i}
                            /> :
                            <input value={item.value.toString().replace(/\s+$/, ' ')}
                                   style={{
                                       width: (item.bmp.toString().trim().length) * 17 / 25 + (item.type === "D" ? 3 : 0) + "rem", //1 zeichen ca 17/25 rem
                                       minWidth: "4rem",
                                       position: isMobil ? "block" : "absolute",
                                       left: item.fieldPosx ? (item.fieldPosx - item.posy) * scalingX : item.caption.length * 6,
                                       top: item.fieldPosy ? (item.fieldPosy - item.posx) * scalingY : undefined
                                   }}
                                   maxLength={item.bmp.toString().trim().length}
                                   onFocus={onFocus(item, i)}
                                   onBlur={onBlur(item)}
                                   onChange={(e) => editing && item.valuePointer && updateValue(item.action, e.target.value, i)}
                                   id={"input_" + i}
                                   inputmode={item.type === "N" ? "decimal" : undefined}
                                   type={item.type === "N" ? "number" : item.type === "D" ? "date" : "text"}
                            /> :
                        <p className={"value"} style={{
                            opacity: item.value.toString().trim() ? 1 : 0.3,
                            width: item.fieldSizex ? item.fieldSizex * scalingX : "max-content",
                            position: isMobil ? "block" : "absolute",
                            left: item.fieldPosx ? (item.fieldPosx - item.posy) * scalingX : item.caption.length * 6,
                            top: item.fieldPosy ? (item.fieldPosy - item.posx) * scalingY : undefined,
                            height: item.fieldSizey ? item.fieldSizey * scalingY : undefined
                        }}
                           onDoubleClick={() => onDblClick(i)}>
                            {item.value.toString().trim() ? item.value : (item.caption.toString().split(":")[0] + "...").substring(0, item.value.toString().length)}
                        </p>}
                    {/*wenn input type date nicht klappt:*/}
                    {/*{editing && selectDate === i && item.valuePointer &&*/}
                    {/*    <div style={{zIndex: 2, position: "absolute", marginTop: 20}}>*/}
                    {/*        <DatePicker selected={isNaN(new Date(item.value)) ? new Date() : new Date(item.value)}*/}
                    {/*                    onChange={(e) => {*/}
                    {/*                        updateValue(item.action, new Date(e).toISOString().split("T")[0]);*/}
                    {/*                        onAction("action: {||edt_feld:='" + item.valuePointer + "',&edt_feld:=ctod('" + new Date(e).toLocaleDateString('de-DE') + "')}");*/}
                    {/*                        setSelectDate(null);*/}
                    {/*                    }}*/}
                    {/*                    inline*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*}*/}
                </div>
            )}
            {search && <MasySearchWindow data={search} setData={setSearch} onAction={onAction} setModal={setModal}
                                         isMobil={isMobil} setTooltip={setTooltip}/>}
            {table && <MasyTableWindow data={table} setData={setTable} onAction={onAction} setModal={setModal}
                                       isMobil={isMobil} setTooltip={setTooltip}/>}
        </div>
    );
}