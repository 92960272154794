import "./style.css";
import icon from "../logo.svg";
import {useEffect} from "react";
import {masyReplace} from "../globals";

export default function MasyToolbar({masyGui, onAction, editing, isMobil}){

    useEffect(() => {
        window.addEventListener("keydown", onKey, false);

        return () => {
            window.removeEventListener("keydown", onKey, false);
        };
    }, [masyGui, editing]);

    function onKey(e){
        console.log("tools menu key " + e.key);
        if(!editing){
            masyGui.buttonsF9?.map((item) => {
                if(item.caption.toString().split(" ")[0].length === 2 && item.caption.toString().split(" ")[0][1].toLowerCase() === e.key){
                    onAction("action: " + item.action);
                    e.stopImmediatePropagation();
                    e.preventDefault();
                }
            });
        }
        (editing ? masyGui.editButtons ?? [] : masyGui.toolbar)?.map((item, i) => {
            let search = item.type?.toString().toLowerCase().match("xbek_(alt_|ctrl_|sh_)?[a-z0-9]+"); //xbeK_F10 xbeK_ALT_F5 xbeK_CTRL_F2 xbeK_SH_F2
            if(search){
                let tiles = search[0].split("_");
                if(tiles[1].replace("esc", "escape") === e.key.toLowerCase() ||
                    (((e.altKey && tiles[1] === "alt") || (e.shiftKey && tiles[1] === "sh") || (e.ctrlKey && tiles[1] === "ctrl"))
                        && tiles.length > 2
                        && tiles[2] === e.key.toLowerCase())){
                    document.activeElement.blur();
                    document.getElementById("button_" + i).click();
                    e.stopImmediatePropagation();
                    e.preventDefault();
                }
            }
        });
    }

    if(!masyGui.toolbar && !editing){
        return null;
    }

    function isActive(item){
        return item.caption.toString().includes("Suche") ||
            item.caption.toString().includes("ndern") ||
            item.caption.toString().includes("Neu") ||
            item.caption.toString().includes("schen") ||
            item.caption.toString().includes("Kopie") ||
            item.caption.toString().includes("Sort") ||
            item.caption.toString().includes("Ende") ||
            item.caption.toString().includes("Tabelle") ||
            editing;
    }

    return (
        <div style={{position: isMobil? "sticky": undefined, top: 50, zIndex: 5}}>
            <div style={{display: "flex", flexDirection: "row", flexWrap: isMobil ? "wrap" : "nowrap"}}>
                {
                    (editing ? masyGui.editButtons ?? [] : masyGui.toolbar).map((item, i) =>
                        item.caption.toString().endsWith("BMP") ?
                            <img alt={item.caption} src={icon}
                                 style={{
                                     width: "60px",
                                     cursor: isActive(item) ? "pointer" : "not-allowed",
                                     display: isActive(item) ? "block" : "none"
                                 }}
                                 title={item.tooltip}
                                 key={"itemImg_" + i}/> :
                            <button style={{
                                width: isMobil? "auto": item.sizex * 15,
                                height: item.sizey * 25,
                                margin: 5,
                                // background: isActive(item) ? undefined : "gray",
                                cursor: isActive(item) ? "pointer" : "not-allowed",
                                display: isActive(item) ? "block" : "none",
                                background: item.caption.toString().includes("Abbruch") || item.caption.toString().includes("Beenden") || item.caption.toString().includes("Abbrechen") || item.caption.toString().includes("Ende")? "var(--red)": undefined,
                                color: item.caption.toString().includes("Abbruch") || item.caption.toString().includes("Beenden") || item.caption.toString().includes("Abbrechen") || item.caption.toString().includes("Ende") ? "var(--lightgray)": undefined
                            }}
                                    className={"button"}
                                    onClick={() => isActive(item) ? onAction("action: " + item.action) : () => {
                                    }} key={"button_" + i} id={"button_" + i}>
                                {item.caption.toString().replace("Kopie", "Duplizieren").replace("Sort", "Sortieren nach")}
                            </button>
                    )
                }
            </div>
            {false && !editing && !isMobil && <div style={{display: "flex", flexFlow: "wrap"}}>
                {
                    masyGui.buttonsF9?.map((item, i) =>
                        item.caption.toString().endsWith("BMP") ?
                            <img alt={item.caption} src={icon} style={{width: "60px"}} title={item.tooltip}/> :
                            <button style={{
                                width: item.sizex * 15,
                                height: item.sizey * 25,
                                margin: 5,
                                background: "gray"
                            }}
                                    className={"button"}
                                    key={"itemF9_" + i}> {masyReplace(item.caption)} </button>
                    )
                }
            </div>}
        </div>
    );
}