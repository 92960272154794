import "./style.css";
import MasyFields from "./MasyFields";
import {overlayStyle} from "./MasySearchWindow";
import {masyReplace, scalingX, scalingY} from "../globals";
import {Button} from "rsuite";
import MasyCloseButton from "./MasyCloseButton";

export default function MasySearchShow({data, onOK, onESC, onAction, isMobil, setTooltip}){

    return (
        <div className={"backgroundBlur"}>
            <div style={overlayStyle(isMobil)}>
                <div className={"title"}>{data.showtitle}</div>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
                    {data.showbuttons?.map((button) =>
                        button.action.includes("DC_ReadGuiEvent(5") && !isMobil ?
                            <MasyCloseButton text={button.caption} onAction={() => onESC()}/> :
                            <button
                                className={"button"}
                                style={{
                                    width: isMobil ? "auto" : button.sizex * scalingX,
                                    height: button.sizey * scalingY,
                                    margin: 10
                                }}
                                onClick={() => {
                                    onAction("action: " + button.action);

                                    if(button.action.includes("DC_ReadGuiEvent(6")){
                                        onOK();
                                    }
                                    else if(button.action.includes("DC_ReadGuiEvent(5")){
                                        onESC();
                                    }
                                }}
                            >
                                {masyReplace(button.caption)}
                            </button>
                    )}
                </div>
                <MasyFields fields={data.showfields} search={false}
                            style={isMobil ? {} : {height: "800px", width: "1150px"}} isMobil={isMobil}
                            setTooltip={setTooltip}/>
            </div>
        </div>
    );
}